import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/default"

import AudioPlayer from "../../../components/audio-player"
import Breadcrumb from "../../../components/breadcrumb"
import Constrain from "../../../components/constrain"
import Grid from "../../../components/grid"
import Image from "../../../components/image"
import PageTitle from "../../../components/page-title"
import Paragraph from "../../../components/paragraph"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"

import LeichteSpracheNext from "../../../content/shared/leichte-sprache-next"

const LeichteSprachePfarrer = () => {
  const data = useStaticQuery(graphql`
    query LeichteSprachePfarrerIndexQuery {
      seminar: file(relativePath: { eq: "leichte-sprache/pfarrer.jpg" }) {
        ...mediumImage
      }
      predigtentwurf: file(
        relativePath: { eq: "leichte-sprache/predigtentwurf.png" }
      ) {
        ...mediumImage
      }
    }
  `)

  return (
    <Layout backdrop="aussen">
      <Seo
        title="Hölderlin soll Pfarrer werden"
        description="Friedrich Hölderlins Mutter will, dass er Pfarrer wird. Deshalb geht Hölderlin in eine Kloster∙schule."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Leichte Sprache",
                link: "/leichte-sprache",
              },
              {
                title: "Über Hölderlin",
                link: "/leichte-sprache/ueber-hoelderlin",
              },
              {
                title: "Pfarrer",
                link: "/leichte-sprache/ueber-hoelderlin/pfarrer",
              },
            ]}
          />
          <PageTitle>Hölderlin soll Pfarrer werden</PageTitle>
          <Constrain align="left">
            <AudioPlayer src="leichte-sprache/04-pfarrer" />
          </Constrain>
          <Paragraph dropcap={true}>
            Friedrich Hölderlins Mutter will, <br />
            dass er Pfarrer wird. <br />
            Deshalb geht Hölderlin in eine Kloster∙schule.
          </Paragraph>
          <Paragraph>
            Dann studiert er in Tübingen. <br />
            Aber Hölderlin ist unglücklich. <br />
            Er will nicht Pfarrer werden. <br />
            Er will Dichter werden.
          </Paragraph>

          <Constrain align="left">
            <Image
              image={data.seminar.childImageSharp.gatsbyImageData}
              attribution="DLA Marbach"
              caption="Hier hat Hölderlin in Tübingen studiert."
              alt="Eine alte Ansicht zeigt ein großes Haus am Fluss Neckar. Es hat 4 Stockwerke und viele Fenster."
            />
          </Constrain>
          <Paragraph>
            Seine Mutter sagt: <br />
            Als Dichter verdienst du kein Geld. <br />
            Du sollst Pfarrer werden. <br />
            Dann verdienst du viel Geld. <br />
            Deshalb studiert Hölderlin weiter. <br />
            Nach dem Studium in Tübingen sagt er aber immer noch: <br />
            Ich will nicht Pfarrer werden.
          </Paragraph>

          <Grid columns={[1, 2]} alignY="center">
            <Image
              image={data.predigtentwurf.childImageSharp.gatsbyImageData}
              caption="Eine Predigt∙übung von Hölderlin"
              attribution="DLA Marbach"
              alt="Ein altes Papier mit Hölderlins Handschrift"
            />
            <Paragraph>
              Für sein Studium muss Hölderlin Predigten schreiben. <br />
              Eine Predigt ist eine Rede für die Kirche. <br />
              Er muss sie vor dem Mittag∙essen <br />
              im Speise∙saal vortragen. <br />
              Um das Predigen zu üben.
            </Paragraph>
          </Grid>
        </Stack>

        <LeichteSpracheNext variant="freunde" />
      </Stack>
    </Layout>
  )
}

export default LeichteSprachePfarrer
